//React components 
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
//Components 
import { Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TransactionPill from "./TransactionPill";
import TransactionHistory from "./TransactionHistory";
import Navbar from '../NavBar/Navbar';
import Modal from 'react-bootstrap/Modal';
import ErrorAlert from '../Alerts/ErrorAlert';
import OrderDetails from '../Orders/OrderDetails';
//Utils 
import Socket from '../utils/Socket.config';
//External modules 
import moment from 'moment-timezone';
//Api 
import { OrdersFetchAPI } from '../../api/Orders';
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';
//img
import empty from '../img/icons/empty.png';
import update from '../img/icons/update-list.png'
import { RenderIf } from '../utils/RenderIf';
//CSS
import "./transaction.css"


function Transactions() {
   //Params
   let { business_code } = useParams();
   //We get the user data 
   let user = JSON.parse(localStorage.getItem('user'))
   let token = localStorage.getItem('x-access-token');
   //States
   const [change_status, setchange_status] = useState(0)
   const [branchOrdersData, setBranchOrdersData] = useState([]);
   const [dataBranchoffice, setDataBranchoffice] = useState(0)
   const [temporal, setTemporal] = useState([]);
   const [userBranchoffices, setUserBranchs] = useState([]);
   const [userBusiness, setUserBusiness] = useState({});
   const [selectedBranch, setSelectedBranch] = useState({
      code: 0
   });
   const [temporalOrdersData, setTemporalOrdersData] = useState([]);
   const [detailOrder, setDetailOrder] = useState();
   const [order_type, setOrderType] = useState("temporal")
   const [modalShow, setModalShow] = useState(false);
   const [reload, setReLoad] = useState(false);
   //We get the actual date 
   const actualDate = moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD')
   //Functions to show and hide the modal 
   const handleCloseModal = () => setModalShow(false);
   const handleShowModal = () => setModalShow(true);

   //Function to gen the empty card to the transaction history 
   const EmptyCard = () => {
      return (
         <div className="m-3 w-auto ">
            <div className="border-history d-flex justify-content-md-center py-4 px-4 " aria-current="true">
               <div className="d-flex w-100 justify-content-center align-items-center  flex-column">
                  <img src={empty} alt="Vacio" width="35px" height="35px" />
                  <div className='text-center mt-3'>
                     <h5>Vacío</h5>
                     <h6 className="mb-0 text-secondary fw-bold">No hay transacciones </h6>
                  </div>
               </div>
            </div>
         </div>
      )
   }

   //We get the transactions by branchoffice 
   const getTransactionsByBranchOffice = async (business_code, branchoffice_code, minDate, maxDate) => {
      try {
         //We call the transaction info 
         const response = await OrdersFetchAPI.getTransactionsByBranchOfficePaymentMethodbyUser(business_code, branchoffice_code, minDate, maxDate, 0, user.id, token);
         setBranchOrdersData(response.data.orders);
         //This are for the discount temporal orders 
         setTemporalOrdersData(response.data.temp_orders);
         //This are for the search filter
         setTemporal(response.data.orders);
      } catch (err) {
         //We show the error alert 
         ErrorAlert('No tiene información')
         console.log(err);
      }
   }

   //We get the temporal transactions by branchoffice 
   const getTemporalOrdersByBranch = async (business_code, branchoffice_code, minDate, maxDate, token) => {
      try {
         //We call the transaction info 
         const response = await OrdersFetchAPI.getTemporalOrdersByBranch(business_code, branchoffice_code, minDate, maxDate, token);
         //This are for the discount temporal orders 
         setTemporalOrdersData(response.data.temp_orders);

         console.log("transacciones temporales")

         //This are for the search filter
         setTemporal(response.data.orders);
      } catch (err) {
         //We show the error alert 
         ErrorAlert('No tiene información')
         console.log(err);
      }
   }

   //get info brancoffice by id
   const getBranchofficebycode = async (branchoffice_code) => {
      try {
         const response = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, branchoffice_code)
         setDataBranchoffice(response.data.branchoffice)

      } catch (err) {
         console.log(err);
         setDataBranchoffice(0)
      }
   }

   //update branchoffice
   const updateBtranchoffice = async () => {

      let state = 0;
      if (dataBranchoffice.branchoffice_open === 0) { state = 1 }
      let data = { branchoffice_open: state }

      try {
         const response = await BranchOfficeFetchAPI.updateBranchoffice(dataBranchoffice.id, data, token);
         setchange_status(1)

      } catch (err) {
         console.log(err);
      }
   }

   //Function to populate the list orders 
   const listOrders = () => {
      const listOrders = branchOrdersData.map((data) => (
         <>
            <div onClick={async () => {
               await getOrderById(data._id);
               handleShowModal();
            }}>
               <TransactionHistory data={data} />
            </div>
         </>
      ));
      //We check if the list orders has data 
      if (listOrders.length === 0) {
         return EmptyCard()
      } else {
         return (listOrders)
      }
   }

   //Function to populate the users branch 
   const branchList = () => {
      const listBracnh = userBranchoffices.map((branch) => (
         <>
            <option value={branch.code}>{branch.branchoffice_name}</option>
         </>
      ));
      return listBracnh
   }

   //Function to populate the temporal list orders 
   const listTemporalOrders = () => {
      const temporalOrders = temporalOrdersData.map((data) => (
         <>
            {data.status_id === 1 ? <>
               <div onClick={async () => {
                  await getTempOrderByCode(data.code);
                  handleShowModal();
               }} >
                  <TransactionPill data={data} />
               </div>
            </> : <></>}
         </>
      ));
      if (temporalOrders.length === 0) {
         return EmptyCard()
      } else {
         return (temporalOrders)
      }
   }

   //Function to populate the order details (modal)
   const detailOrderList = () => {
      return detailOrder === undefined ? "" : <OrderDetails temporalOrders={temporalOrdersData} setTemporalOrdersData={setTemporalOrdersData} orderData={detailOrder} order_type={order_type} business_data={userBusiness} setLoad={setReLoad} setModalShow={setModalShow} />
   }

   //Function to search any result on the search filter 
   const handleChangeFiltro = (event) => {
      let resultFilter = [];
      if (event.target.value === "") {
         console.log(event.target.value);
         setBranchOrdersData(temporal);
      } else {
         for (
            let recorrer = 0;
            recorrer < branchOrdersData.length;
            recorrer++
         ) {
            const examinar = branchOrdersData[recorrer];

            const info = JSON.stringify(examinar);
            const resultado = info
               .toLowerCase()
               .includes(event.target.value.toLowerCase());

            if (resultado === true) {
               resultFilter.push(examinar);

               setBranchOrdersData(resultFilter);
               console.log(resultFilter);
            }
         }
      }
   }

   //Function to get order by id (order_id)
   const getOrderById = async (order_id) => {
      try {
         const response = await OrdersFetchAPI.getOrderById(order_id, token);
         setDetailOrder(response.data.order)
         setOrderType("permanent")
      } catch (err) {
         setDetailOrder({})
         setOrderType("permanent")
      }
   }

   //Function to get a temporal order (for the espcial discounts notifications)
   const getTempOrderByCode = async (order_code) => {
      try {
         const response = await OrdersFetchAPI.getTempOrderByCode(order_code, business_code, selectedBranch.code, token);
         setDetailOrder(response.data.order)
         setOrderType("temporal")
      } catch (err) {
         setDetailOrder({})
         setOrderType("temporal")
      }
   }

   useEffect(() => {
      setTemporalOrdersData([...temporalOrdersData]);
      setReLoad(0)
   }, [reload])

   //Hook to charge data when the page is need to be reloaded 
   useEffect(() => {
      (async () => {
         try {
            //We set the branchoffices of the user 
            setUserBranchs(user.branchoffices);
            //We set the business user 
            setUserBusiness(user.business);
            //We set the temporal data 
            setTemporalOrdersData(temporalOrdersData)
            //We set the branh orders data 
            setBranchOrdersData(branchOrdersData);
            setTemporal(branchOrdersData);
         } catch (err) {
            console.log(err);
         }
      })();
      //We set the load state 
      setReLoad(false)
      //We set the selected branch 
      setSelectedBranch(selectedBranch);
      localStorage.setItem('branchoffice_selected', selectedBranch.code);
   }, [reload, selectedBranch]);

   //Hook to conect the client to the sockets and make the initial charge of data 
   useEffect(() => {
      // Connection with the sockets server
      const socket = Socket({ "business_code": business_code })
      // we upate the data when the server send the signal of a new order 
      // Update of the data when the server send the signal of new order for this business 
      socket.on(`businessOrder_${business_code}`, (newOrder) => {
         let selected_branch = localStorage.getItem('branchoffice_selected')
         //We set the branchoffice data order
         setBranchOrdersData((prevOrders) => {
            let index_user_branchoffices = user.branchoffices.findIndex(branch => newOrder.branchoffice.code === branch.code);
            //We check that the new order is from any branch available to this user 
            if (index_user_branchoffices !== -1) {
               //We add the order if the order comes from an avalaible branch or a selected branc by the user
               if (selected_branch === 0 || selected_branch === '0' || selected_branch === newOrder.branchoffice.code) {
                  return [newOrder, ...prevOrders]
               }
            }
            return prevOrders;
         });
      });

      // Update of business temp orders (discount and devolutions)
      socket.on(`businessTempOrder_${business_code}`, (newTempOrder) => {
         let selected_branch = localStorage.getItem('branchoffice_selected')
         //We set the temporal orders data 
         setTemporalOrdersData((prevTempOrders) => {
            let index_user_branchoffices = user.branchoffices.findIndex(branch => newTempOrder.branchoffice.code === branch.code);
            //We check that the new order is from any branch available to this user 
            if ((index_user_branchoffices !== -1 && newTempOrder.is_discount_notification === true) || (newTempOrder.is_devolution_notification === true)) {
               //We add the order if the order comes from an avalaible branch or a selected branc by the user
               if (selected_branch === 0 || selected_branch === '0' || selected_branch === newTempOrder.branchoffice.code) {
                  return [newTempOrder, ...prevTempOrders]
               }
            }
            return prevTempOrders
         });
      });

      //Update of business temp orders when the temp order is deleted 
      socket.on(`deleteBusinessTempOrder_${business_code}`, (deletedTempOrder) => {
         setTemporalOrdersData((prevTempOrders) => {
            //We get the order position 
            const order_index = prevTempOrders.findIndex(tempOrder => tempOrder._id === deletedTempOrder._id);
            //We delete the prev order 
            prevTempOrders.splice(order_index, 1);
            return prevTempOrders
         })
         //We set the load state 
         setReLoad(true)
         //We close the modal 
         setModalShow(false)
      });

      return () => {
         socket.disconnect();
      }
   }, [business_code]);

   //Hook to charge initial data hen the page is loaded 
   useEffect(() => {
      //We set the branchoffices of the user 
      setUserBranchs(user.branchoffices);
      //We set the business user 
      setUserBusiness(user.business);
      //We get the transactions
      getTemporalOrdersByBranch(business_code, selectedBranch.code, actualDate, actualDate + 'T23:59:59.000+00:00', token);
      localStorage.setItem('branchoffice_selected', 0)
   }, [business_code]);


   useEffect(() => {
      getBranchofficebycode(dataBranchoffice.code)
      setchange_status(0)
   }, [change_status]);

   return (
      <>
         <div className='sp-grid bg-white no-scroll position-relative g-0'>
            <div className=' fixed-column'>
               <Navbar titlePage="Transacciones" />
            </div>
            <div className='other-column '>
            </div>
            <main className='m-2'>
               <section className='banner-sp d-lg-flex justify-content-lg-between  align-items-center rounded-4 mx-2  p-2 '>
                  <div>
                     <div className='d-flex justify-content-between  align-items-center'>
                        <h2 className='banner-reports-title mb-0 pb-0'>
                           Transacciones
                        </h2>
                        <RenderIf isTrue={dataBranchoffice !== 0}>
                           <input type="checkbox" class="btn-check" id="btn-check-outlined" autocomplete="off"
                              checked={dataBranchoffice.branchoffice_open === 1 ? true : false} onChange={() => updateBtranchoffice()} />
                           <label class={dataBranchoffice.branchoffice_open === 1 ? "" : ""} for="btn-check-outlined">
                              {dataBranchoffice.branchoffice_open === 1 ? <>
                                 <div className="ms-1 mb-0  badge bg-success d-inline">Abierto <i class="uil uil-toggle-on"></i></div> </> :
                                 <><div className="ms-1  badge bg-danger d-inline">Cerrado <i class="uil uil-toggle-off"></i></div> </>}
                           </label>
                        </RenderIf>
                     </div>
                     <p className='m-0 p-0 banner-reports-description'>Transacciones diarias de pagos en tiempo real.</p>
                  </div>

                  <form className='filter-sp-trn'>
                     <div className="d-block">
                        <select onChange={e => { getBranchofficebycode(e.target.value); setSelectedBranch({ code: e.target.value }); getTransactionsByBranchOffice(business_code, e.target.value, actualDate, actualDate + 'T23:59:59.000+00:00') }} className='form-select' name="user_branch" id="user_branch">
                           <option value={0}> Mostrar todos</option>
                           {branchList()}
                        </select>
                     </div>
                  </form>
               </section>

               <section className='row  mt-2 py-2 px-2'>
                  <article className='col-12 col-lg-6'>
                     <div className='d-flex justify-content-between align-items-center mb-2'>
                        <h4 className='banner-sp-subtitle'>Transacciones con  Descuento/Devoluciones</h4>

                        <button className='btn-ht-update btn bg-primary-subtle rounded-circle p-2 lh-1 border-primary' title="Actualizar Lista"
                           onClick={e => getTemporalOrdersByBranch(business_code, selectedBranch.code, actualDate, actualDate + 'T23:59:59.000+00:00', token)} >
                           {/* <img src={update} alt="Update List" width="16" height="16" /> */}
                           <img className='' width="25" height="25" src={update} alt="restart" />
                           {/* <small> Actualizar </small> */}
                        </button>
                     </div>
                     <div className='rounded-3 '>
                        <div className='border rounded-4 bg-white'>

                           <div className=' payment-smart mx-3'>
                              <div className='transaction-wrapper border border-history no-scroll'>
                                 {/* Boton para actualizar las ordenes */}
                                 <div>
                                    {listTemporalOrders()}

                                 </div>
                              </div>
                           </div>

                        </div>
                     </div>

                  </article>
                  <article className='col-12 col-lg-6 mt-lg-0 mt-4'>
                     <div className='d-flex justify-content-between align-items-center mb-2'>
                        <h4 className='banner-sp-subtitle'>Historial de Transacciones</h4>

                        <button className='btn-ht-update btn bg-primary-subtle rounded-circle p-2 lh-1 border-primary' title="Actualizar Lista"
                           onClick={e => getTransactionsByBranchOffice(business_code, selectedBranch.code, actualDate, actualDate + 'T23:59:59.000+00:00')} >
                           {/* <img src={update} alt="Update List" width="16" height="16" /> */}
                           <img className='' width="25" height="25" src={update} alt="restart" />
                           {/* <small> Actualizar </small> */}
                        </button>
                     </div>

                     <div className='rounded-3'>
                        <div className='border rounded-4 bg-white'>
                           <div className='mx-lg-3'>
                              <div className="w-100 bg-light px-3 py-2 border rounded mt-4">
                                 <div className="d-flex align-items-center">
                                    <h6 className="mb-0 text-secondary fw-bold">Filtar:</h6>
                                    <div className='border-0 fw-bold fs-3 ms-1  w-100'>
                                       <input
                                          spellcheck="false"
                                          className="form-control w-100"
                                          type="text"
                                          step="any"
                                          onChange={handleChangeFiltro}
                                          placeholder="Filtar por...  "
                                       />
                                    </div>
                                 </div>
                              </div>

                              <div className='transaction-wrapper-2  no-scroll mx-lg-0'>
                                 {listOrders()}
                              </div>
                           </div>
                        </div>
                     </div>
                  </article>
               </section>
            </main>
         </div>

         {/* Modal */}
         <Modal show={modalShow} onHide={handleCloseModal} >
            <Modal.Header closeButton>
               <Modal.Title>Detalles</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               {detailOrderList()}
            </Modal.Body>
         </Modal >
      </>
   )
}

export default Transactions