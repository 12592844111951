//React components
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Nav from 'react-bootstrap/Nav';
import Select from 'react-select'
//API
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';


//Internal components 
import NavBar from '../NavBar/Navbar';
import Table from '../Table/Table';
//Modals 
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
const Wherehouse = () => {
 

    return (
        <>
            <div className='sp-grid bg-white no-scroll position-relative g-0'>
                Bodegas
            </div>
        </>
    );
}

export default Wherehouse;
