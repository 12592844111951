import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import ErrorAlert from '../Alerts/ErrorAlert';
import { ExcelModuleFechtAPI } from '../../api/ExcelModule';
import {ProgressBar} from 'react-bootstrap';
// import { FilePond, File, registerPlugin } from 'react-filepond'
// Import FilePond styles
// import 'filepond/dist/filepond.min.css'


function ModalImport({ title, load }) {
    const [showModal, setShowModal] = useState(false)
    let token = localStorage.getItem("x-access-token")
    let user = localStorage.getItem('user');
    let [template, setTemplate] = useState('')
    const [progress, setProgress] = useState()
    let [label, setLabel] =useState('')


    const handlefile = (e) => {
        e.preventDefault() //prevent the form from submitting

    
        console.log(Math.round((100 * e.loaded) / e.total));
            //Set the progress value to show the progress bar
        setProgress(Math.round((100 * e.loaded) / e.total))
    
      }

    //Function to register the product 
    const submit = async (e) => {
        e.preventDefault();
        user = JSON.parse(localStorage.getItem('user'))

        const form = new FormData(e.target);
        const importFiles = Object.fromEntries(form.entries());



        try {
            //We update the data 

            if (title == "producto") {
                const response = await ExcelModuleFechtAPI.UploadBusinessGroup(user.business_id, importFiles, token)
                console.log(response);

            } else if (title == "acompañante") {
                const response = await ExcelModuleFechtAPI.UploadBusinessSegments(user.business_id, importFiles, token)
                console.log(response);
            } else if (title == "producto individual") {
                const response = await ExcelModuleFechtAPI.UploadBusinessItem(user.business_id, importFiles, token)
                console.log(response);
            }

            //We show the success alert 

            ErrorAlert('', 'Plantilla subida con exito', 'success')
            load();
            setShowModal(false);
        } catch (err) {
            ErrorAlert(err.response.data.error, 'Error de actualizacion', 'error')
            console.log(err.response.data.error)
        }
    }

    //function get template
    const getFileDownload = async () => {
        user = JSON.parse(localStorage.getItem('user'))
        if (title == "producto") {
            setLabel("Aqui puedes importar productos, o importar los productos con acompañantes y productos individuales.")
            setTemplate('/excel/download/excel/product/template')

        } else if (title == "acompañante") {
            setLabel("Aqui puedes importar acompañantes.")
            setTemplate('/excel/download/excel/segment/template')
            

        } else if (title == "producto individual") {
             setLabel("Aqui puedes importar productos individuales.")
            setTemplate('/excel/download/excel/item/template')

        }


    }

    return (
        <>
            <button className='btn btn-dark btn-sm  fw-normal bg-body text-dark border border-1 rounded-2'
                onClick={() => (setShowModal(true), getFileDownload())} type="button"
            >
                <i className="uil uil-airplay"> </i> Importar

            </button>
            <Modal
                show={showModal}
                onHide={() => (setShowModal(false))}
                size='large'
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-airplay"></i>
                        Importar {title} </h3>

                    <div className=" d-flex justify-content-end">

                        <a href={`${process.env.REACT_APP_API_URL}${template}`} className="btn btn-success">
                            <i className="fas fa-download">-</i>
                            Descargar plantilla
                        </a>
                    </div>
                </Modal.Header>
                <Modal.Body>

                    <Form
                        className="needs-validation"
                        onSubmit={submit}
                    >

                        <Form.Group className="mb-3">
                            <Form.Label>{label}</Form.Label>
                            <Form.Control type="file"
                                id="upload-button"
                                name="files"
                                onChange={((e) => handlefile(e))}

                            />
                        </Form.Group>
                 




                        <div className='d-flex justify-content-between'>
                            <Button size="sm" onClick={() => (setShowModal(false))}>Cerrar</Button>
                            <Button size="sm" variant="success" type="submit">Guardar</Button>
                        </div>

                    </Form>



                </Modal.Body>

            </Modal >

        </>
    )
}

export default ModalImport