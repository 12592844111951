import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, ListGroup } from 'react-bootstrap';
import { RenderIf } from '../utils/RenderIf';

const SmartpayModal = ({
    button_text,
    modal_size,
    modal_header_text,
    modal_body,
    submit,
    handleFieldChange,
    buttonModal,
    setShowModal,
    showModal,
    initialFormValues,
    formType,
    removeUserBranch,
    selectedUserOption,
    setEventHandler,
    buttonIcon,
    buttonClass
}) => {
    // We create the state of the form
    const [formValues, setFormValues] = useState(initialFormValues || {});
    //We create the useEffect
    useEffect(() => {
        setFormValues(initialFormValues || {});
    }, [initialFormValues]);

    //We create the input function 
    const handleInputChange = (name, value) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        handleFieldChange(name, value);
    };

    //We create the checkbox function 
    const handleCheckboxChange = (name, value) => {
        setFormValues((prevValues) => {
            const newValues = { ...prevValues };
            if (!newValues[name]) {
                newValues[name] = [];
            }
            if (newValues[name].includes(value)) {
                newValues[name] = newValues[name].filter((v) => v !== value);
            } else {
                newValues[name].push(value);
            }
            // Notifica el cambio después de actualizar el estado
            handleFieldChange(name, newValues[name]);
            return newValues;
        });
    };

    //We create the form of the modal
    const modalBodyForm = () => (
        <Form onSubmit={submit} className="row needs-validation">
            {modal_body.map((field, index) => (
                <Form.Group key={index} className="col-12 col-lg-4 my-1">
                    <Form.Label htmlFor={field.name} className='fw-bold'>{field.label}</Form.Label>
                    {field.type === 'select' ? (
                        <Form.Select
                            id={field.name}
                            name={field.name}
                            onChange={(e) => handleInputChange(field.name, e.target.value)}
                            defaultValue={formValues[field.name] || field.defaultValue}
                            required={field.required}
                        >
                            {field.options.map((option, index) => (
                                <option key={index} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Form.Select>
                    ) : field.type === 'checkbox' && formType === "addForm" ? (
                        <>
                            {field.options.map((option, index) => (
                                <Form.Check
                                    key={index}
                                    type="checkbox"
                                    id={option.id}
                                    name={field.name}
                                    label={option.name}
                                    value={option.id}
                                    checked={formValues[field.name]?.includes(option.id) || false}
                                    onChange={() => handleCheckboxChange(field.name, option.id)}
                                />
                            ))}
                        </>
                    ) :
                        field.type === 'checkbox' && formType === "editForm" ? (
                            <>
                                <ListGroup as="ol" >
                                    {field.options.map((option, index) => (
                                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                            <div className="ms-4 me-auto" >
                                                {option.branchoffice_name}
                                                <button className='btn btn-outline-danger btn-sm border-0' type='button' onClick={() => removeUserBranch(selectedUserOption.user_id, option.branchoffice_id)} >
                                                    <i className="uil uil-trash-alt"></i>
                                                </button>
                                            </div>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </>
                        ) :
                            field.type === 'checkbox' && formType === "addformGeneric" ? (
                                <>
                                    {field.options.map((option, index) => (
                                        <Form.Check
                                            key={index}
                                            type="checkbox"
                                            id={option.id}
                                            name={field.name}
                                            label={option.name}
                                            value={option.id}
                                            checked={formValues[field.name]?.includes(option.id) || false}
                                            onChange={() => handleCheckboxChange(field.name, option.id)}
                                        />
                                    ))}
                                </>
                            ) :
                                (
                                    <Form.Control
                                        type={field.type}
                                        id={field.name}
                                        name={field.name}
                                        placeholder={field.placeholder}
                                        onChange={(e) => handleInputChange(field.name, e.target.value)}
                                        value={formValues[field.name] || ''}
                                        required={field.required}
                                    />
                                )}
                </Form.Group>
            ))}
            <div className="mt-3 d-flex justify-content-end">
                <Button size="lg" variant="success" type="submit">
                    Guardar
                </Button>
            </div>
        </Form>
    );

    return (
        <>
            {/** We ask if the button of modal is neccesary  */}
            <RenderIf isTrue={buttonModal === true}>
                <div>
                    <button
                        className={buttonClass === undefined ? 'btn btn-primary border-0' : buttonClass}
                        onClick={() => {
                            //We show the modal
                            setShowModal(true);
                            //We set the event handler if it exists the event handler
                            if (setEventHandler !== undefined) setEventHandler(1)
                        }}>
                        <i className={buttonIcon == undefined ? 'uil uil-plus-circle' : buttonIcon}></i>
                        
                        
                         {button_text}
                    </button>
                </div>
            </RenderIf>

            {/** Modal */}
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                size={modal_size}
                centered
            >
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-apps"></i>
                        {modal_header_text}
                    </h3>
                </Modal.Header>
                <Modal.Body>
                    {modalBodyForm()}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShowModal(false)}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default SmartpayModal;
